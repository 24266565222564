import React from 'react'
import "../style/Loder.css"
const Loader = () => (
    
    <div className="loader">
        <div className="spinner"></div>
    </div>
)


export default Loader